import React, { useState } from "react";
import Dialog from "components/Dialog";

export default function ConfigDeleteClassroom(
  {
    classroom,
    formAuthenticityToken,
  },
): JSX.Element {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <section className="page__section">
      <div className="page__container">
        <div className="page__section-head">
          <h2 className="page__title --level-2">
            教室の削除
          </h2>
        </div>

        <div className="page__item">
          <div>
            <div className="page__data-body">
              <button
                className="button -appearance-outlined -size-s -color-negative"
                type="button"
                style={{ padding: "0 3rem" }}
                onClick={() => setShowDeleteDialog(true)}
              >
                <div>
                  教室の削除
                </div>
              </button>
            </div>

            {showDeleteDialog && (
              <Dialog
                title={`教室「${classroom.name}」を削除`}
                description={
                  <>
                    教室の全てのデータが削除されます。<br />
                    教室に受講者がいる場合、教室から自動で退会となるため同意のうえで操作をお願いします。<br />
                    削除されたデータの復旧はできかねますのでご了承ください。
                  </>
                }
                onCloseButtonClick={() => setShowDeleteDialog(false)}
              >
                <form
                  id="classroom-delete-form"
                  action={classroom.managementOrganizationPath}
                  method="post"
                >
                  <input
                    type="hidden"
                    name="_method"
                    value="delete"
                    autoComplete="off"
                  />

                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={formAuthenticityToken}
                    autoComplete="off"
                  />

                  <button
                    className="button -appearance-transparent -size-m -color-negative"
                    type="submit"
                  >
                    <div className="_body">
                      削除する
                    </div>
                  </button>
                </form>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
